import React from "react"
import "./footer.css"
import { Link } from "gatsby"
import Mailchimp from 'react-mailchimp-form'

import logo from '../../images/logo/logo.svg'

export default function Footer() {
    return (



        <div className="footer-area-wrapper" style={{ background: '#fbfbfb' }}>
            <div className="footer-area section-space--ptb_120">
                <div className="container">
                    <div className="row footer-widget-wrapper">
                        <   div className="col-lg-3 col-md-4 col-sm-6 footer-widget">
                            <h6 className="footer-widget__title mb-20">Vibra</h6>
                            <ul className="footer-widget__list">
                                <li><Link to="/" className="hover-style-link">Home</Link></li>
                                <li><Link to="/vibra-one" className="hover-style-link">Vibra One</Link></li>
                               {/*<li><Link to="/vibra-four" className="hover-style-link">Vibra Four</Link></li>*/}
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 footer-widget">
                            <h6 className="footer-widget__title mb-20">Help & Information</h6>
                            <ul className="footer-widget__list">
                                <li><Link to="/contact-us" className="hover-style-link">Help & Contact Us</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6 footer-widget">
                            <h6 className="footer-widget__title mb-20">About Us</h6>
                            <ul className="footer-widget__list">
                                <li><Link to="/about-us" className="hover-style-link">About Us</Link></li>
                                <li><Link to="/contact-us" className="hover-style-link">Contact Us</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 footer-widget">
                            <h6 className="footer-widget__title mb-20">Newsletter</h6>
                            <div className="footer-widget__newsletter mt-30">
                                Our newsletters are a concentrated blast of all the latest from Vibra: news, events, product information, and more. <br /><br />
                                <Mailchimp
                                    action='https://vibrahub.us7.list-manage.com/subscribe/post?u=95855aed4330e67093dea96b3&amp;id=03991f77c6'
                                    buttonClassName={"submit-button"}
                                    fields={[
                                        {
                                            name: 'EMAIL',
                                            placeholder: 'Your email address',
                                            type: 'email',
                                            required: true,
                                        }
                                    ]}
                                    messages={{
                                        sending: "Sending...",
                                        success: "Thank you for subscribing!",
                                        error: "An unexpected internal error has occurred.",
                                        empty: "You must write an e-mail.",
                                        duplicate: "Too many subscribe attempts for this email address",
                                        button: "->"
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-copyright-area section-space--pb_30">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4 col-md-5 text-center text-md-left">

                            <ul className="footer-widget__footer-menu">
                                <li><Link to="/terms-and-conditions">Terms & Conditions</Link></li>
                                <li><Link to="/privacy-policy">Policy</Link></li>
                                <li><Link to="/sitemap.xml">Map</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-4 col-md-2 text-center">
                            <div className="footer-logo">
                                <Link to="/"><img src={logo} alt="Vibra Hub Logo" /></Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-5 order-md-3">
                            <div className="footer-bottom-social">
                                <h6 className="title">Follow Us On Social</h6>
                                <ul className="list footer-social-networks ">
                                    <li className="item">
                                        <Link to="https://www.pinterest.com/vibrahub/" target="_blank" aria-label="Pinterest">
                                            <i className="social social_pinterest"></i>
                                        </Link>
                                    </li>
                                    <li className="item">
                                        <Link to="https://www.facebook.com/vibrahub" target="_blank" aria-label="Facebook">
                                            <i className="social social_facebook"></i>
                                        </Link>
                                    </li>
                                    <li className="item">
                                        <Link to="https://www.instagram.com/vibrahub/" target="_blank" aria-label="Instagram">
                                            <i className="social social_instagram"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <span className="copyright-text text-center  section-space--mt_40">&copy; 2021 Vibra Hub. All Rights Reserved.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    )
}
