import React, { useState } from "react"
import { Link } from "gatsby"
import "./dropdownMenu.css"




export const DropdownMenu = () => {
    return (
        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-80 dropdown hasmenu">
            <a className="dropdown-toggle">Products</a>

            <ul className="dropdown-submenu">
                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-406">
                    <Link to="/vibra-one">Vibra One</Link>
                </li>
            </ul>
        </li>
    )
}

export default DropdownMenu;