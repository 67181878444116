import React, { useState } from "react"
import "./header.css"
import { Link } from "gatsby"
import { DropdownMenu } from '../ui_components/dropdownMenu/dropdownMenu'
import logo from '../../images/logo/logo.svg'

// markup
export const Header = ({ data }) => {

    const [active,
        setActive] = useState(false);
    const [isSticky,
        setSticky] = useState(false);

    const menuToggle = () => {
        setActive(true);

        document
            .body
            .classList
            .add("no-overflow");
    }

    const menuClose = () => {
        setActive(false);

        document
            .body
            .classList
            .remove("no-overflow");
    }

    if (typeof window !== 'undefined') {


        const $ = window.$;

        var $body = $('body');




        var windows = $(window);
        var screenSize = windows.width();
        var sticky = $('.header-sticky');

        windows.on('scroll', function () {
            var scroll = windows.scrollTop();
            var headerHeight = sticky.height();

            if (screenSize >= 320) {
                if (scroll < headerHeight) {
                    setSticky(false);
                } else {
                    setSticky(true);
                }
            }
        });



        $body
            .on('click', function (e) {
                var $target = e.target;
                if (!$($target).is('.mobile-menu-overlay__inner') && !$($target).parents().is('.mobile-menu-overlay__inner') && !$($target).is('#mobile-menu-trigger') && !$($target).is('#mobile-menu-trigger i')) {
                    setActive(false);
                    document
                        .body
                        .classList
                        .remove("no-overflow");
                }
            });

        /*=============================================
        =            offcanvas mobile menu            =
        =============================================*/
        var $offCanvasNav = $('.offcanvas-navigation'),
            $offCanvasNavSubMenu = $offCanvasNav.find('.sub-menu');

        /*Add Toggle Button With Off Canvas Sub Menu
        $offCanvasNavSubMenu
            .parent()
            .prepend('<span class="menu-expand"><i></i></span>');
*/
        /*Close Off Canvas Sub Menu*/
        $offCanvasNavSubMenu.slideUp();

        /*Category Sub Menu Toggle
        $offCanvasNav.on('click', 'li a, li .menu-expand', function (e) {
            var $this = $(this);
            if (($this.parent().attr('class').match(/\b(menu-item-has-children|has-children|has-sub-menu)\b/)) && ($this.attr('href') === '#' || $this.hasClass('menu-expand'))) {
                e.preventDefault();
                if ($this.siblings('ul:visible').length) {
                    $this
                        .parent('li')
                        .removeClass('active');
                    $this
                        .siblings('ul')
                        .slideUp();
                } else {
                    $this
                        .parent('li')
                        .addClass('active');
                    $this
                        .closest('li')
                        .siblings('li')
                        .removeClass('active')
                        .find('li')
                        .removeClass('active');
                    $this
                        .closest('li')
                        .siblings('li')
                        .find('ul:visible')
                        .slideUp();
                    $this
                        .siblings('ul')
                        .slideDown();
                }
            }
        });
*/
        /*----------------------------------------*/
        /*  Offcanvas
        /*----------------------------------------*/
        /*Variables*/
        var $offcanvasNav = $('.offcanvas-menu, .offcanvas-minicart_menu, .offcanvas-search_menu, .mobile-menu'),
            $offcanvasNavWrap = $('.offcanvas-menu_wrapper, .offcanvas-minicart_wrapper, .offcanvas-search_wrapper,' +
                ' .mobile-menu_wrapper'),
            $offcanvasNavSubMenu = $offcanvasNav.find('.sub-menu'),
            $menuToggle = $('.menu-btn'),
            $menuClose = $('.btn-close');

        /*Close Off Canvas Sub Menu*/
        $offcanvasNavSubMenu.slideUp();

        $('.btn-close').on('click', function (e) {
            e.preventDefault();
            $('.mobile-menu .sub-menu').slideUp();
            $('.mobile-menu .menu-item-has-children').removeClass('menu-open');
        })

        /*----------------------------------------*/
        /*  Offcanvas Inner Nav
        /*----------------------------------------*/
        $('.offcanvas-inner_nav li.has-sub > a, .frequently-item li.has-sub a, .pd-tab_item' +
            ' li.has-sub a').on('click', function () {
                $(this).removeAttr('href');
                var element = $(this).parent('li');
                if (element.hasClass('open')) {
                    element.removeClass('open');
                    element
                        .find('li')
                        .removeClass('open');
                    element
                        .find('ul')
                        .slideUp();
                } else {
                    element.addClass('open');
                    element
                        .children('ul')
                        .slideDown();
                    element
                        .siblings('li')
                        .children('ul')
                        .slideUp();
                    element
                        .siblings('li')
                        .removeClass('open');
                    element
                        .siblings('li')
                        .find('li')
                        .removeClass('open');
                    element
                        .siblings('li')
                        .find('ul')
                        .slideUp();
                }
            });
    }

    return (
        <main>
            <div className="header-area header-area--default bg-white">

                <header className={`header-area header_absolute header_height-90 header-sticky ${isSticky ? 'is-sticky' : ''}`}>
                    <div className="container-fluid container-fluid--cp-100">
                        <div className="row align-items-center">

                            <div className="col-lg-3 col-lg-3 col-6">
                                <div className="logo text-left">
                                    <Link to="/"><img src={logo} /></Link>
                                </div>
                            </div>

                            <div className="col-lg-6 col-lg-6  d-none d-lg-block">
                                <div className="header__navigation d-none d-lg-block">
                                    <nav className="navigation-menu main-navigation primary-navigation">
                                        <ul className="justify-content-center menu">
                                            <li>
                                                <Link to="/">Home</Link>
                                            </li>
                                            <DropdownMenu />
                                            <li>
                                                <Link to="/get-a-quote">Get a quote</Link>
                                            </li>
                                            <li>
                                                <Link to="/virtual-demo">Contact us</Link>
                                            </li>
                                            <li>
                                                <Link to="/about-us">About us</Link>
                                            </li>
                                            
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-lg-3 col-lg-3 col-6">
                                <div className="header-right-side text-right">

                                    <div className="header-right-items ">
                                        <a
                                            href="#"
                                            className="mobile-navigation-icon"
                                            id="mobile-menu-trigger"
                                            onClick={menuToggle}>
                                            <i className="icon-menu"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

            </div>

            <div
                className={'mobile-menu-overlay ' + (active
                    ? 'active'
                    : '')}
                id="mobile-menu-overlay">

                <div className="mobile-menu-overlay__inner">

                    <div className="mobile-menu-close-box text-left">
                        <span
                            className="mobile-navigation-close-icon"
                            id="mobile-menu-close-trigger"
                            onClick={menuClose}>
                            <i className="icon-cross2"></i>
                        </span>
                    </div>

                    <div className="mobile-menu-overlay__body">
                        <nav className="offcanvas-navigation">
                            <ul>
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <Link to="/vibra-one">Vibra One</Link>
                                </li>
                                <li>
                                    <Link to="/get-a-quote">Get a quote</Link>
                                </li>
                                <li>
                                    <Link to="/about-us">About us</Link>
                                </li>
                                <li>
                                    <Link to="/contact-us">Contact Us</Link>
                                </li>

                            </ul>
                        </nav>


                        <div className="mobile-menu-social-share section-space--mt_60">
                            <h6>Follow US On Socials</h6>
                            <ul className="social-share">
                                <li>
                                    <a href="https://www.facebook.com/vibrahub">
                                        <i className="social social_facebook"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/vibrahub/">
                                        <i className="social social_instagram"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.pinterest.com/vibrahub/">
                                        <i className="social social_pinterest"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>

                    </div>

                </div>

            </div>

        </main>
    )
}

export default Header

