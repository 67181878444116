import React from "react"
import Header from "./header/header"
import Footer from "./footer/footer"
import CookieConsent from 'react-cookie-consent'
import SEO from './seo/seo';

import "../styles/vendor/bootstrap.min.css"
import "../styles/vendor/linearicons.min.css"
import "../styles/vendor/fontawesome-all.min.css"
import "../styles/plugins/animation.min.css"
import "../styles/plugins/slick.min.css"
import "../styles/plugins/magnific-popup.css"
import "../styles/plugins/easyzoom.css"
import "../styles/style.css"

import "../styles/global.css"

export default function Layout({ seo , children, location }) {



    return (
        <main>
            <SEO/>
            <Header></Header>
            {children}
            <Footer></Footer>


            <CookieConsent
                style={{ background: "#000000a8" }}
                buttonStyle={{
                    background: "#c5622b",
                    color: "white",
                }}
                declineButtonStyle={{ background: "#173842" }}
                flipButtons enableDeclineButton
                buttonText="Accept"
                declineButtonText="Decline"
                cookieName="gatsby-gdpr-google-analytics">
                This website uses cookies to enhance the user experience.{" "}
            </CookieConsent>

        </main>
    )
}
